import Header from "../../Prepage/Header/Header"
//Libraries
import ReactPlayer from "react-player"

import "./Player.scss"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    fetchCourseItem,
    fetchSingleCollection,
    loadingCollection,
    loadingCourseItem,
    postNewTimeInVideo,
    saveUserMedia,
    toggleChapterOpen,
} from "../../../actions/mediaAction"
import { useParams } from "react-router-dom"
import Spinner from "../../Commons/Spinner/Spinner"
import ChapterMenu from "./ChapterMenu/ChapterMenu"
import ChatButton from "./ChatButton/ChatButton"
import Banner from "../../Banner/Banner"
import Overlay from "./Overlay/Overlay"
import { askReview, getJsonCookie, isBeforeLastChapter, isMiddleVideoAsking, setJsonCookie } from "../../../selectors/selectors"
import ReviewForm from "./ReviewForm/ReviewForm"

const Player = () => {
    const dispatch = useDispatch()
    const { videoSlug } = useParams()
    const { collectionId } = useParams();
    const isLoading = useSelector((state) => state.media.loadingCourse)
    const isLoadingCollection = useSelector((state) => state.media.loadingCollection)
    const coursData = useSelector((state) => state.media.singleCourse)
    const light = useSelector((state) => state.media.light)
    const userId = useSelector((state) => state.user.userId)
    const isOpenChapters = useSelector((state) => state.media.chapterOpen)
    const ref = useRef(null)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const overlayOpen = useSelector((state) => state.media.overlayOpen)
    const loadingCustomerReview = useSelector((state) => state.user.loadingCustomerReview)
    const collection = useSelector((state) => state.media.collection);
    const isVideoPlaying = useSelector((state) => state.media.isVideoPlaying);
    const customerReviews = useSelector((state) => state.user.customerReviews)
    const reviewFormOpen = useSelector((state) => state.media.reviewFormOpen)


    useEffect(() => {
        dispatch(loadingCourseItem(true))
        dispatch(fetchCourseItem(videoSlug))
        dispatch(loadingCollection(true))
        dispatch(fetchSingleCollection(collectionId));


    }, [dispatch, videoSlug, userId])

    useEffect(() => {
        if (window.matchMedia("(min-width: 1000px)").matches) {
            dispatch(toggleChapterOpen(true))
        }
    }, [dispatch])
    useEffect(() => {
        if (window.matchMedia("(max-width: 998px)").matches) {
            dispatch(toggleChapterOpen(false))
        }
    }, [dispatch])


    return (
        <div>
            <Banner />
            <Header />
            {!isLoading && (
                <div className="player-and-menu-wrapper">
                    <div
                        onClick={() => {
                            dispatch(toggleChapterOpen(true))
                        }}
                        className={`to-open-chapter${!isOpenChapters ? " display" : " displayNone"}`}>
                        Plan du cours{" "}
                        <img
                            src={
                                "https://static.artesane.com/images_react/fleche.png"
                            }
                            alt=""
                        />
                    </div>
                    <div
                        className={`chapter-menu-wrapper${isOpenChapters ? " open" : " closed"}`}>
                        <ChapterMenu
                            playerRef={ref}
                            courseTitle={coursData.name}
                            chapters={coursData.chapters}
                        />
                    </div>
                    <div className="player-wrapper">
                        <ChatButton />
                        <ReactPlayer
                            onProgress={(event) => {
                                const time = Math.ceil(event.playedSeconds)
                                const cookies = getJsonCookie('reviews') || [];
                                const video = document.querySelector("video");


                                if (time % 15 === 0 && time > 0) {
                                    dispatch(
                                        postNewTimeInVideo(time, coursData.id)
                                    )
                                }

                                if(askReview(customerReviews, collection.collection.products.length > 0 ? collection.collection.products[0].product['@id'] : '')) {
                                    if(
                                        isMiddleVideoAsking(time, Math.round(coursData.duree), coursData.chapters) 

    
                                    ) {
    
                                        if(cookies.length === 0 || !(cookies.find((item) => item.id === collection.collection.products[0].product['@id']))) {
                                            cookies.push({id : collection.collection.products[0].product['@id'], name : collection.collection.name, middleVideoAsking : true, endVideoAsking :false})
                                            setJsonCookie("reviews", cookies, 180)
                                            dispatch(saveUserMedia("isVideoPlaying", false))


                                            if (video && video.webkitEnterFullscreen) {
                                                video.webkitExitFullscreen(); 
                                            } else if (document.fullscreenElement || document.webkitFullscreenElement) {
                                                if (document.exitFullscreen) {
                                                    document.exitFullscreen();  
                                                } else if (document.webkitExitFullscreen) {
                                                    document.webkitExitFullscreen();  
                                                }
                                            }

                                             window.scrollTo({ top: 0, behavior: "smooth" })
                                            dispatch(saveUserMedia("overlayButtonContent", "Je le donne plus tard"))
                                            dispatch(saveUserMedia("overlayOpen", true))
                                        } else {
                                            const cookieFind = cookies.find((item) => item.id === collection.collection.products[0].product['@id'] )
    
                                            if(!cookieFind['middleVideoAsking']) {
                                                dispatch(saveUserMedia("isVideoPlaying", false))
                                                if (video && video.webkitEnterFullscreen) {
                                                    video.webkitExitFullscreen(); 
                                                } else if (document.fullscreenElement || document.webkitFullscreenElement) {
                                                    if (document.exitFullscreen) {
                                                        document.exitFullscreen();  
                                                    } else if (document.webkitExitFullscreen) {
                                                        document.webkitExitFullscreen();  
                                                    }
                                                }
                                                window.scrollTo({ top: 0, behavior: "smooth" })
                                                dispatch(saveUserMedia("overlayButtonContent", "Je le donne plus tard"))
                                                dispatch(saveUserMedia("overlayOpen", true))
                                                cookieFind['middleVideoAsking'] = true
                                                setJsonCookie("reviews", cookies, 180)
                                            }
    
                                        }
    
     
    
                                    }  else if (
                                        isBeforeLastChapter(time, Math.round(coursData.duree))
                                    ) {
    
                                        if(cookies.length === 0 || !(cookies.find((item) => item.id === collection.collection.products[0].product['@id'])) ) {
                                                                                
                                            dispatch(saveUserMedia("isVideoPlaying", false))
                                            if (video && video.webkitEnterFullscreen) {
                                                video.webkitExitFullscreen(); 
                                            } else if (document.fullscreenElement || document.webkitFullscreenElement) {
                                                if (document.exitFullscreen) {
                                                    document.exitFullscreen();  
                                                } else if (document.webkitExitFullscreen) {
                                                    document.webkitExitFullscreen();  
                                                }
                                            }
                                             window.scrollTo({ top: 0, behavior: "smooth" })
                                            dispatch(saveUserMedia("overlayOpen", true))
                                            cookies.push({id : collection.collection.products[0].product['@id'], name : collection.collection.name, middleVideoAsking : false, endVideoAsking: true})
                                            setJsonCookie("reviews", cookies, 180)
    
                                        } else {
    
                                            const cookieFind = cookies.find((item) => item.id === collection.collection.products[0].product['@id'] )
    
                                            if(!cookieFind['endVideoAsking']) {
                                                cookieFind['endVideoAsking'] = true
                                                setJsonCookie("reviews", cookies, 180)
                                                dispatch(saveUserMedia("isVideoPlaying", false))
                                                if (video && video.webkitEnterFullscreen) {
                                                    video.webkitExitFullscreen(); 
                                                } else if (document.fullscreenElement || document.webkitFullscreenElement) {
                                                    if (document.exitFullscreen) {
                                                        document.exitFullscreen();  
                                                    } else if (document.webkitExitFullscreen) {
                                                        document.webkitExitFullscreen();  
                                                    }
                                                }
                                                  window.scrollTo({ top: 0, behavior: "smooth" })
                                                dispatch(saveUserMedia("overlayOpen", true))
                                            }
    
                                        }
    
                                           
                                        
      
                                    }
                                }



                            }}
                            ref={ref}
                            controls={true}
                            onStart={() => {
                                const savedTimes = coursData.savedTimes
                                ref.current.seekTo(
                                    Object.keys(savedTimes).length > 0
                                        ? savedTimes.timeInVideo
                                        : 0,
                                    "seconds"
                                )
                            }}
                            light={
                                light
                                    ? coursData.artesane_sellable_item_image_url_original
                                    : false
                            }
                            config={{
                                forceHLS: !isSafari,
                                hlsOptions: {
                                    xhrSetup: function (xhr, url) {
                                        xhr.withCredentials = true // send cookies
                                    },
                                },
                            }}
                            url={
                                coursData.access.URL.replace(
                                "*",
                                "index.m3u8"
                            )

                        }
                            playing={isVideoPlaying}
                            playIcon={
                                <img
                                    className="player-media-logo"
                                    src={
                                        "https://static.artesane.com/images_react/white_player.png"
                                    }
                                    alt="player"
                                />
                            }
                            width={"100%"}
                            height={"100%"}
                        />
                        {overlayOpen && !isLoadingCollection && !loadingCustomerReview && (
                            <Overlay/>
                        )}
                        {reviewFormOpen && (
                            <ReviewForm productName={collection.collection.products[0].product.name} productUri={collection.collection.products[0].product['@id']}/>
                        )}
                    </div>
                </div>
            )}
            {isLoading && (
                <div className="spinner-wrapper">
                    {" "}
                    <Spinner />
                </div>
            )}
        </div>
    )
}
export default Player
