import { useDispatch, useSelector } from 'react-redux';
import { postReview, saveUserMedia } from '../../../../actions/mediaAction';
import Rating from '@mui/material/Rating';
import './ReviewForm.scss';
import Spinner from '../../../Commons/Spinner/Spinner';
import InputSimple from '../../../UserAccount/InputSimple/inputSimple';

const ReviewForm = ({productName, productUri}) => {

    const dispatch = useDispatch()
    const reviewFormRate = useSelector((state) => state.media.reviewFormRate)
    const isLoadingCollection = useSelector((state) => state.media.loadingCollection)
    const reviewFormContent = useSelector((state) => state.media.reviewFormContent)
    const reviewTitle = useSelector((state) => state.media.reviewTitle)
    const errorRate = useSelector((state) => state.media.errorRate)
    const errorContent = useSelector((state) => state.media.errorContent)
    const loadingReviewFormSubmit = useSelector((state) => state.media.loadingReviewFormSubmit)
    const customerEmail = useSelector((state) => state.user.email);
    const reviewConfirm = useSelector((state) => state.media.reviewConfirm)


    return (
        <div className='review-form-wrapper'>

            
            <div className='review-form'>
            <div
                className='cross'
                onClick={() => {
                    dispatch(saveUserMedia("reviewFormOpen", false))
                    dispatch(saveUserMedia("isVideoPlaying", true));
                    dispatch(
                        saveUserMedia('errorRate', false)
                    )
                    dispatch(
                        saveUserMedia('errorContent', false)
                    )
                        dispatch(
                            saveUserMedia('loadingReviewFormSubmit', false)
                        )
                }}
            >
                ╳
            </div>
                <div className='review-form-title'>
                   <div>Une minute pour vous, une aide précieuse pour nous !</div>
                    <div> Merci pour votre retour sur le produit "{productName}"</div>
                </div>
                {!reviewConfirm && (
                            <form
                            className='review-form-container'
                            onSubmit={(event) => {
                                event.preventDefault();
        
                                if(reviewFormRate < 1 ) {
                                    dispatch(
                                        saveUserMedia('errorRate', true)
                                    )
                                }
        
                                if(reviewFormContent.trim().length < 3) {
                                    dispatch(
                                        saveUserMedia('errorContent', true)
                                    )
                                }
        
                                if(reviewFormRate > 0 && reviewFormContent.trim().length > 2 ) {
                                    dispatch(
                                        saveUserMedia('loadingReviewFormSubmit', true)
                                    )
                                    dispatch(postReview(reviewFormRate, reviewFormContent, reviewTitle,  productUri, customerEmail))
                                }
                            }}
                        >
                            <div className={`${errorRate ?  'input-value error' : 'input-value'}`}>
                                <label  htmlFor="">Votre note : </label>
                                <Rating
                                    name="simple-controlled"
                                    value={reviewFormRate}
                                    size="large"
                                    onChange={(event, newValue) => {
                                        dispatch(saveUserMedia('reviewFormRate', newValue));
                                        dispatch(
                                            saveUserMedia('errorRate', false)
                                        )
                                    }}
                                />
                                {errorRate && (
                                    <div className='input-information error'>
                                        Une étoile minimum est requise
                                    </div>
                                )}
                            </div>
                            <InputSimple
                                inputName={"reviewTitle"}
                                label={"Titre"}
                                value={reviewTitle}
                                type={"text"}
                                required={false}
                                onChange={(event) => {
                                    dispatch(saveUserMedia('reviewTitle', event.target.value));
                                    
                                }}
                            />
                            <div className={`${errorContent ?  'input-value error' : 'input-value'}`}>
                                <label htmlFor="description">Votre avis</label>
                                <textarea
                                    minLength={3}
                                    value={reviewFormContent}
                                    name="description"
                                    cols="30"
                                    rows="6"
                                    onChange={(event) => {
                                        dispatch(
                                            saveUserMedia('reviewFormContent', event.target.value)
                                        )
                                        dispatch(
                                            saveUserMedia('errorContent', false)
                                        )
                                    }}></textarea>
                                {errorContent && (
                                    <div className='input-information error'>
                                        Votre commentaire doit contenir au minimum 3 caractères
                                    </div>
                                )}
                            </div>
                            <button type='submit'>
                                valider
                            </button>
                        </form>
                )}
                {reviewConfirm && (
                    <div className='review-confirm'>
                        <div>
                            Votre avis a bien été envoyé !
                        </div>
                        <img src={`https://static.artesane.com/images_react/favicon.png`} alt="artesane" />
                        <button
                            onClick={() => {
                                dispatch(saveUserMedia("isVideoPlaying", true));
                                dispatch(saveUserMedia("reviewFormOpen", false))
                                dispatch(saveUserMedia("reviewConfirm", false))

                            }}
                        >
                        fermer X</button>
                    </div>
                )}

            </div>
            

            {loadingReviewFormSubmit && (
                <Spinner/>
            )}
        </div>
    )
}

export default ReviewForm;