export const selectRightLink = (currentSlug, slugData) => {
    const rightlink = slugData.filter((navItem) => {
        return navItem.slug !== currentSlug
    })
    return rightlink
}

export const selectRightTaxons = (myState, taxonToSearch) =>
    myState[taxonToSearch]

export const selectRightMenuData = (myState, currentLocation) => {
    if (currentLocation === "arts-culinaires") {
        return myState["megaMenuCulinaires"]
    }
    if (currentLocation === "arts-du-fil") {
        return myState["megaMenuFil"]
    }
    if (currentLocation === "arts-plastiques") {
        return myState["megaMenuPlastiques"]
    }
}

export const selectHomeUniversActif = (currentLocation) => {
    if (currentLocation === "arts-culinaires") {
        return 103
    }
    if (currentLocation === "arts-du-fil") {
        return 101
    }
    if (currentLocation === "arts-plastiques") {
        return 102
    }
}

export const selectHomeUniversActifSlug = (id) => {
    if (id === 103) {
        return "arts-culinaires"
    }
    if (id === 101) {
        return "arts-du-fil"
    }
    if (id === 102) {
        return "arts-plastiques"
    }
}

export const selectTitleByUnivers = (slug) => {
    if (slug === "arts-du-fil") {
        return "Arts du fil"
    }
    if (slug === "arts-plastiques") {
        return "Arts plastiques"
    }
    if (slug === "arts-culinaires") {
        return "Arts culinaires"
    }
}

export const countQuantity = (buyOnce, cartData, variantsCode) => {
    if (buyOnce === false && cartData.items !== undefined) {
        if (cartData.items.length > 0) {
            const itemsFind = cartData.items.find(
                (element) =>
                    element.ArtesaneProductVariant_code === variantsCode
            )

            if (itemsFind) {
                return itemsFind.quantity + 1
            } else {
                return 1
            }
        } else {
            return 1
        }
    } else {
        return 1
    }
}

export const countOtherFiltersResult = (
    filtersChecked,
    filter,
    allProduct,
    productToShow,
    priceValues
) => {
    const productsToshow = dePaginator(productToShow)

    let count = 0

    //Si on compte dans le cas des filtres qui ne proviennent du parent courant
    if (!filtersChecked.find((item) => item.parent === filter.parent)) {
        productsToshow.forEach((product) => {
            if (filter.parentName === "Professeur") {
                if (product.author !== null) {
                    if (product.author.id === filter.id) {
                        count = count + 1
                    }
                }
            } else if (filter.parentName === "Nos offres spéciales") {
                if (filter.name === "Nouveautés") {
                    if (product.isNew) {
                        count = count + 1
                    }
                } else if (filter.name === "Petits prix") {
                    if (product.variants[0].price / 100 <= 20) {
                        count = count + 1
                    }
                } else if (filter.name === "Promotions") {
                    if (
                        product.variants[0].originalPrice !==
                        product.variants[0].price
                    ) {
                        count = count + 1
                    }
                } else if (filter.name === "Les gratuits") {
                    if (product.variants[0].price / 100 === 0) {
                        count = count + 1
                    }
                } else if (filter.name === "Meilleures ventes") {
                    if (product.bestsSales) {
                        count = count + 1
                    }
                }
            } else {
                if (product.taxons.find((element) => element === filter.id)) {
                    count = count + 1
                }
            }
        })

        // Dans le cas des autres filtres enfants du parent courant
    } else {
        let newFiltersChecked = filtersChecked.filter(
            (element) => element.parent !== filter.parent
        )
        newFiltersChecked.push({
            parent: filter.parent,
            children: [filter.id],
            parentName: filter.parentName,
            names: [filter.name],
        })

        count = dePaginator(
            filterProduct(newFiltersChecked, allProduct, priceValues, [])
        ).length
    }

    return count
}

export const filterProduct = (
    filtersChecked,
    allProduct,
    priceValues,
    choixTri,
    productTaxons
) => {
    let productToShow = []

    if (filtersChecked.length > 0) {
        filtersChecked.forEach((filtre) => {
            let productByFilterParent = []
            allProduct.forEach((produit) => {
                if (filtre.parentName === "Professeur") {
                    // On regarde si le produit a pour profeseur le professeur courant

                    if (produit.author !== null) {
                        if (
                            filtre.children.find(
                                (element) => element === produit.author.id
                            )
                        ) {
                            if (
                                !productByFilterParent.find(
                                    (element) => element.id === produit.id
                                )
                            ) {
                                productByFilterParent.push(produit)
                            }
                        }
                    }
                } else if (filtre.parentName === "Nos offres spéciales") {
                    if (filtre.names.find((item) => item === "Nouveautés")) {
                        if (produit.isNew) {
                            if (
                                !productByFilterParent.find(
                                    (element) => element.id === produit.id
                                )
                            ) {
                                productByFilterParent.push(produit)
                            }
                        }
                    } else if (
                        filtre.names.find((item) => item === "Petits prix")
                    ) {
                        if (produit.variants[0].price / 100 <= 20) {
                            if (
                                !productByFilterParent.find(
                                    (element) => element.id === produit.id
                                )
                            ) {
                                productByFilterParent.push(produit)
                            }
                        }
                    } else if (
                        filtre.names.find((item) => item === "Les gratuits")
                    ) {
                        if (produit.variants[0].price / 100 === 0) {
                            if (
                                !productByFilterParent.find(
                                    (element) => element.id === produit.id
                                )
                            ) {
                                productByFilterParent.push(produit)
                            }
                        }
                    } else if (
                        filtre.names.find((item) => item === "Promotions")
                    ) {
                        if (
                            produit.variants[0].price !==
                            produit.variants[0].originalPrice
                        ) {
                            if (
                                !productByFilterParent.find(
                                    (element) => element.id === produit.id
                                )
                            ) {
                                productByFilterParent.push(produit)
                            }
                        }
                    } else if (
                        filtre.names.find(
                            (item) => item === "Meilleures ventes"
                        )
                    ) {
                        if (produit.bestsSales) {
                            if (
                                !productByFilterParent.find(
                                    (element) => element.id === produit.id
                                )
                            ) {
                                productByFilterParent.push(produit)
                            }
                        }
                    }
                } else {
                    if (produit.taxons.length > 0) {
                        produit.taxons.forEach((item) => {
                            if (
                                filtre.children.find(
                                    (element) => element === item
                                )
                            ) {
                                // Si le produit n'est pas déjà dans la liste
                                if (
                                    !productByFilterParent.find(
                                        (element) => element.id === produit.id
                                    )
                                ) {
                                    productByFilterParent.push(produit)
                                }
                            }
                        })
                    }
                }
            })
            productToShow.push(productByFilterParent)
        })

        // Maintenant on croise les produits qu'on trouve dans chaque tableau
        let tableauFinal = productToShow[0]

        function trouverObjetsCommunsParId(tableau1, tableau2) {
            return tableau1.filter((obj1) =>
                tableau2.some((obj2) => obj2.id === obj1.id)
            )
        }

        for (let i = 0; i < productToShow.length; i++) {
            tableauFinal = trouverObjetsCommunsParId(
                tableauFinal,
                productToShow[i]
            )
        }

        productToShow = tableauFinal
    } else if (productTaxons.length > 0) {
        allProduct.forEach((produit) => {
            if (productTaxons.find((item) => item.product === produit["@id"])) {
                productToShow.push(produit)
            }
        })
    } else {
        productToShow = allProduct
    }

    productToShow = productToShow
        ? productToShow.filter(
              (element) =>
                  priceValues[0] <= element.variants[0].price / 100 &&
                  element.variants[0].price / 100 <= priceValues[1]
          )
        : []

    productToShow = paginator(productToShow)
    productToShow = sort(productToShow, choixTri)

    return productToShow
}

export const dePaginator = (productsWithPagination) => {
    let products = []

    productsWithPagination.forEach((element) => {
        element.forEach((item) => {
            products.push(item)
        })
    })

    return products
}

export const sort = (productsWithPagination, choixTri) => {
    let products = dePaginator(productsWithPagination)

    if (choixTri === "PlusCher") {
        products.sort(function (a, b) {
            return b.variants[0].price - a.variants[0].price
        })
    }

    if (choixTri === "MoinsCher") {
        products.sort(function (a, b) {
            return a.variants[0].price - b.variants[0].price
        })
    }

    if (choixTri === "PlusRecent") {
        products.sort(function (a, b) {
            return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
        })
    }

    if (choixTri === "MoinsRecent") {
        products.sort(function (a, b) {
            return (
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
        })
    }

    //Inclure pagination ici
    products = paginator(products)

    return products
}

export const paginator = (products) => {
    let productsTablesPerPage = []

    const numPage = Math.ceil(products.length / 32)

    for (let i = 0; i <= numPage - 1; i++) {
        if (32 * (i + 1) < products.length - 1) {
            productsTablesPerPage.push(products.slice(32 * i, 32 * (i + 1)))
        } else {
            productsTablesPerPage.push(products.slice(32 * i))
        }
    }

    return productsTablesPerPage
}

export const checkFiltersOrNot = (
    filtersChecked,
    parentFilterId,
    id,
    parentFilterName,
    name
) => {
    let newfilters = []
    //Si pas encore de filtres cochés
    if (filtersChecked.length === 0) {
        newfilters = [
            {
                parent: parentFilterId,
                children: [id],
                parentName: parentFilterName,
                names: [name],
            },
        ]

        //Sinon on regarde si le parent existe déjà dans les filtres cochés
    } else {
        newfilters = filtersChecked

        const parentToSearch = newfilters.find(
            (element) => element.parent === parentFilterId
        )
        const indexParent = newfilters.indexOf(parentToSearch)

        if (parentToSearch) {
            //Si on trouve le filtre enfant dans la liste : on le retire
            if (parentToSearch.children.find((child) => child === id)) {
                if (parentToSearch.children.length === 1) {
                    newfilters = newfilters.filter(
                        (element) => element.parent !== parentFilterId
                    )
                } else {
                    const indexToDelete =
                        newfilters[indexParent].children.indexOf(id)
                    newfilters[indexParent].children.splice(indexToDelete, 1)
                    newfilters[indexParent].names.splice(indexToDelete, 1)
                }
                // Sinon ajoute le filtre
            } else {
                newfilters[indexParent].children.push(id)
                newfilters[indexParent].names.push(name)
            }
        } else {
            newfilters.push({
                parent: parentFilterId,
                children: [id],
                parentName: parentFilterName,
                names: [name],
            })
        }
    }
    return newfilters
}
export const checkFiltersName = (
    filtersName,
    id,
    parentFilterId,
    parentFilterName,
    nom
) => {
    let newNameFilters = []

    if (filtersName.length > 0) {
        newNameFilters = filtersName
        if (filtersName.find((element) => element.id === id)) {
            newNameFilters = newNameFilters.filter((item) => item.id !== id)
        } else {
            newNameFilters.push({
                name: nom,
                id: id,
                parent: parentFilterId,
                parentName: parentFilterName,
            })
        }
    } else {
        newNameFilters.push({
            name: nom,
            id: id,
            parent: parentFilterId,
            parentName: parentFilterName,
        })
    }

    return newNameFilters
}

export const addBestSalesBool = (products, univers) => {
    let productsWithBool = products

    productsWithBool.forEach((product) => {
        if (product.best_in_universes.find((item) => item.code === univers)) {
            product["bestsSales"] = true
        } else {
            product["bestsSales"] = false
        }
    })

    return productsWithBool
}

export const menuToCheckFilters = (items, filters, taxons) => {
    let categoriesMenu = []
    let pagelistDescription = ""
    let pagelistTitle = ""
    let productTaxons = []
    let newfilters = []
    let newName = []
    if (items && filters !== (undefined && "tout")) {
        categoriesMenu = items.categories
        pagelistDescription = items.description
        pagelistTitle = items.name
        productTaxons = items.productTaxons
    }

    if (taxons !== undefined) {
        taxons.forEach((parent) => {
            parent.children.forEach((enfant) => {
                if (categoriesMenu.length > 0) {
                    if (categoriesMenu.find((item) => item === enfant["@id"])) {
                        let name = checkFiltersName(
                            newName,
                            enfant.id,
                            parent.id,
                            parent.name,
                            enfant.name
                        )
                        let filters = checkFiltersOrNot(
                            newfilters,
                            parent.id,
                            enfant.id,
                            parent.name,
                            enfant.name
                        )
                        newfilters = filters
                        newName = name
                    }
                }
            })
        })
    }

    return {
        categoriesMenu: categoriesMenu,
        newfilters: newfilters,
        newName: newName,
        description: pagelistDescription,
        productTaxons: productTaxons,
        title: pagelistTitle,
    }
}

export const productsToTeacher = (products) => {
    let teachers = []

    products.forEach((item) => {
        if (item.author !== null) {
            if (
                !teachers.find((author) => author["@id"] === item.author["@id"])
            ) {
                teachers.push(item.author)
            }
        }
    })

    return teachers
}


export const askReview = (customerReviews, currentProduct) => {

    if(currentProduct === "") {
        return false
    }
    const hasReviewed = customerReviews.some(item => item.reviewSubject === currentProduct);
    
    return !(hasReviewed);
};

export const getCookie = (cookieName) => {
    // Sépare les différents cookies par un point-virgule
    var cookies = document.cookie.split(";")

    // Parcours chaque cookie
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i]

        // Supprime les espaces au début et à la fin du nom du cookie
        while (cookie.charAt(0) === " ") {
            cookie = cookie.substring(1)
        }

        // Si le nom du cookie correspond, retourne sa valeur
        if (cookie.indexOf(cookieName + "=") === 0) {
            return cookie.substring(cookieName.length + 1, cookie.length)
        }
    }

    // Si le cookie n'a pas été trouvé, retourne une chaîne vide
    return ""
}

export const setCookie = (name, value, days) => {
    var expiration = ""
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expiration = "; expires=" + date.toUTCString()
    }
    document.cookie =
        name + "=" + value + "; domain=.artesane.com" + expiration + "; path=/"
}



export const setJsonCookie = (name, jsonValue, days) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(JSON.stringify(jsonValue)) + expires + "; path=/; secure; samesite=strict";

}



export const isMiddleVideoAsking = (currentTime, duree, chapters) => {
    const closestChapter =  chapters.reduce((closest, chapter) => 
        Math.abs(chapter.getSecondsInVideo - (duree/2)) < Math.abs(closest.getSecondsInVideo - (duree/2)) ? chapter : closest
    );

    if(currentTime === closestChapter.getSecondsInVideo) {
        return true
    } else {
        return false
    }
}

export const isBeforeLastChapter = (currentTime, duree) => {

    console.log(currentTime);
    console.log(duree - 45)
    return (duree - 45) === currentTime
}


export const getJsonCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
        let [key, value] = cookie.split("=");
        if (key === name) {
            return JSON.parse(decodeURIComponent(value));
        }
    }
    return null;
}

// Fonction pour trier les collections par matières
export const trierCollectionsParMatieres = (associations, univers) => {
    const matieresCollections = {}

    // Parcourir chaque collection pour récupérer toutes les matières
    associations.forEach((association) => {
        let matiereFound = false

        if (association.collection.collectionTaxons !== undefined) {
            association.collection.collectionTaxons.forEach(
                (collectionTaxon) => {
                    if (collectionTaxon !== undefined) {
                        if (collectionTaxon.taxon.parent !== null) {
                            if (
                                collectionTaxon.taxon.parent.name
                                    .toLowerCase()
                                    .includes("matière" || "matières")
                            ) {
                                const matiereId = collectionTaxon.taxon.id
                                const matiereName = collectionTaxon.taxon.name
                                if (!matieresCollections[matiereId]) {
                                    matieresCollections[matiereId] = {
                                        matiere: {
                                            name: matiereName,
                                        },
                                        collections: [],
                                    }
                                }
                                matieresCollections[matiereId].collections.push(
                                    association
                                )
                                matiereFound = true
                            } else {
                                matiereFound = false
                            }
                        }
                    }
                }
            )
        }

        if (!matiereFound) {
            // Aucune matière correspondante trouvée, classer dans la catégorie "autre"
            if (!matieresCollections["autre"]) {
                matieresCollections["autre"] = {
                    matiere: {
                        name: "Autre",
                    },
                    collections: [],
                }
            }
            matieresCollections["autre"].collections.push(association)
        }
    })

    const result = []

    for (const matiereId in matieresCollections) {
        if (matiereId !== "mes_derniers_cours_visionnes") {
            const { matiere, collections } = matieresCollections[matiereId]
            result.push({
                matiere: matiere,
                collections: collections,
            })
        }
    }
    return result
}

export const trierCollectionParUnivers = (associations, univers) => {
    let associationSort = []
    associations.forEach((association) => {
        if (association.collection.universes.length > 0) {
            if (
                association.collection.universes.find(
                    (item) => item.code === univers
                )
            ) {
                associationSort.push(association)
            }
        } else {
            if (univers === "arts-du-fil") {
                associationSort.push(association)
            }
        }
    })

    return associationSort
}

export const lastCollectionsWatched = (associations) => {
    const coursesSort = []

    associations.forEach(({ collection }, index) => {
        if (collection.last_view !== null) {
            coursesSort.push(associations[index])
        }
    })

    coursesSort.sort(
        (a, b) =>
            new Date(b.collection.last_view).getTime() -
            new Date(a.collection.last_view).getTime()
    )

    if (coursesSort.length >= 4) {
        return coursesSort.slice(0, 3)
    }

    return coursesSort
}

export function trouverIndexParId(array, searchId) {
    // Créez un tableau contenant uniquement les IDs des objets
    const ids = array.map((obj) => obj.id)
    // Utilisez indexOf pour trouver l'index de l'ID recherché
    return ids.indexOf(searchId)
}

export const SeoCreator = (metaTitle, name, description, keywords, url) => {

    const pattern = /\/(\d+)$/;  // Recherche un chiffre à la fin de l'URL
    const match = url.match(pattern);

    if(url.includes("catalogue")) {

        if (match) {
            const number = match[1];  // Récupère le chiffre
            if(number > 1 ){
                document.title = document.title = (metaTitle !== null ? metaTitle + " - Page " + number : name + " - Artesane") 
            } else {
                document.title = document.title = (metaTitle !== null ? metaTitle : name + " - Artesane") 
            }
         
        } else {
            document.title = document.title = (metaTitle !== null ? metaTitle : name + " - Artesane") 

        }
    } else {
        document.title = (metaTitle !== null ? metaTitle : name + " - Artesane") 
    }

    const metaDescription = document.querySelector("meta[name='description']")
    metaDescription.setAttribute(
        "content",
        description !== null
            ? description
            : "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
    )

    const metaKeywords = document.querySelector("meta[name='keywords']")
    metaKeywords.setAttribute(
        "content",
        keywords !== null
            ? keywords
            : "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs "
    )

    const metaOGDescription = document.querySelector(
        "meta[property='og:description']"
    )
    metaOGDescription.setAttribute(
        "content",
        description !== null
            ? description
            : "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
    )

    const metaOGTitle = document.querySelector("meta[property='og:title']")
    metaOGTitle.setAttribute(
        "content",
        (metaTitle !== null ? metaTitle : name + " - Artesane") 
    )
    const metaOGUrl = document.querySelector("meta[property='og:url']")
    metaOGUrl.setAttribute("content", "https://artesane.com" + url)
    const linkCanonical = document.querySelector("link[rel='canonical']")
    linkCanonical.setAttribute("href", "https://www.artesane.com" + url)
    const metaRobots = document.querySelector("meta[name='robots']")
    metaRobots.setAttribute("content", "index, follow")
}
export const getYoutubeThumbnailUrl = (videoUrl) => {
    return new Promise((resolve, reject) => {
        // Regex pour extraire l'ID de la vidéo
        const videoIdMatch = videoUrl.match(
            /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
        )

        if (videoIdMatch && videoIdMatch[1]) {
            const videoId = videoIdMatch[1]
            // Les différentes qualités à tester
            const thumbnailQualities = [
                "maxresdefault.jpg",  // Meilleure qualité
                "sddefault.jpg",      // Qualité standard
                "hqdefault.jpg",      // Haute qualité
                "mqdefault.jpg"       // Moyenne qualité
            ]

            // Fonction pour tester la hauteur de l'image
            const testImage = (index) => {
                const img = new Image();
                img.src = `https://img.youtube.com/vi/${videoId}/${thumbnailQualities[index]}`;

                img.onload = function() {
                    if (img.height > 100 || index === thumbnailQualities.length - 1) {
                        // Si la hauteur est correcte ou si on a épuisé toutes les options
                        resolve(img.src);  // Retourner l'URL correcte
                    } else {
                        // Tester la prochaine qualité
                        testImage(index + 1);
                    }
                }

                img.onerror = function() {
                    // Si l'image échoue, passer à la qualité suivante
                    if (index < thumbnailQualities.length - 1) {
                        testImage(index + 1);
                    } else {
                        reject('Aucune vignette valide trouvée');
                    }
                }
            }

            // Commencer à tester avec la première qualité
            testImage(0);
        } else {
            reject('URL YouTube invalide');
        }
    });
}


export const sortMediaProduct = (images, youtube, lightSize) => {
    youtube.forEach((item, index) => {
        if (item.position === undefined || item.position === 0) {
            item["position"] = -1 * (index + 1) // Position négative pour s'assurer qu'ils sont avant les images
        }
    })

    images.forEach((item, index) => {
        if (item.position === undefined || item.position === 0) {
            item["position"] = index + 1
        }
    })

    let mediaConcat = youtube.concat(images)
    return mediaConcat.sort((a, b) => a.position - b.position)
}

export const filterGiftcards = (giftcards) => {
    let filterGiftcards = []

    giftcards.forEach((item) => {
        if (item.amount > 0 && item.enabled) {
            if (item.expiresAt !== undefined) {
                if (new Date(item.expiresAt) > new Date()) {
                    filterGiftcards.push(item)
                }
            } else {
                return filterGiftcards.push(item)
            }
        }
    })

    return filterGiftcards
}

export const isAddressesSame = (shipping, billing) => {
        return (
            shipping['firstName'].trim().toLowerCase() === billing['firstName'].trim().toLowerCase() &&
            shipping['lastName'].trim().toLowerCase() === billing['lastName'].trim().toLowerCase() &&
            shipping['phoneNumber'].trim().toLowerCase() === billing['phoneNumber'].trim().toLowerCase() &&
            shipping['countryCode'].trim().toLowerCase() === billing['countryCode'].trim().toLowerCase() &&
            shipping['street'].trim().toLowerCase() === billing['street'].trim().toLowerCase() &&
            shipping['city'].trim().toLowerCase() === billing['city'].trim().toLowerCase() &&
            shipping['postcode'].trim().toLowerCase() === billing['postcode'].trim().toLowerCase()
        );
    }

