export const CHANGE_INPUT_VALUE = "CHANGE_INPUT_VALUE"
export const TOGGLE_POP_UP_PASSWORD = "TOGGLE_POP_UP_PASSWORD"
export const IS_ERROR_PATTERN_PASSWORD = "IS_ERROR_PATTERN_PASSWORD"
export const IS_ERROR_CONFIRM_PASSWORD = "IS_ERROR_CONFIRM_PASSWORD"
export const VISIBILITY_PASSWORD = "VISIBILITY_PASSWORD"
export const CHANGE_IMAGE_FILE = "CHANGE_IMAGE_FILE"
export const CHANGE_IMAGE_URL = "CHANGE_IMAGE_URL"
export const FETCH_USER_DATA = "FETCH_USER_DATA"
export const CHECK_SAVE = "CHECK_SAVE"
export const FETCH_ORDERS = "FETCH_ORDERS"
export const SAVE_ORDERS = "SAVE_ORDERS"
export const TOGGLE_ITEMS_ORDER = "TOGGLE_ITEMS_ORDER"
export const FETCH_ORDER_ITEMS = "FETCH_ORDER_ITEMS"
export const SAVE_ORDER_ITEMS = "SAVE_ORDER_ITEMS"
export const LOADING_ORDER_ITEMS = "LOADING_ORDER_ITEMS"
export const TOGGLE_POPUP_MODIFY_ADDRESS = "TOGGLE_POPUP_MODIFY_ADDRESS"
export const IS_ERROR_PATTERN_EMAIL = "IS_ERROR_PATTERN_EMAIL"
export const DEFAULT_ADDRESS_ACTIF = "DEFAULT_ADDRESS_ACTIF"
export const SAVE_WISHLIST = "SAVE_WISHLIST"
export const PUT_MODIFY_ADDRESS = "PUT_MODIFY_ADDRESS"
export const LOADING_MODIFY_ADDRESS = "LOADING_MODIFY_ADDRESS"
export const FETCH_COUNTRIES = " FETCH_COUNTRIES"
export const SAVE_COUNTRIES = "SAVE_COUNTRIES"
export const ERROR_MODIFY_ADDRESS = " ERROR_MODIFY_ADDRESS"
export const POST_NEW_ADDRESS = "POST_NEW_ADDRESS"
export const LOADING_ADD_ADDRESS = "LOADING_ADD_ADDRESS"
export const TOGGLE_POPUP_ERROR = "TOGGLE_POPUP_ERROR"
export const DELETE_ADDRESS = "DELETE_ADDRESS"
export const LOADING_DELETE_ADDRESS = " LOADING_DELETE_ADDRESS"
export const PATCH_MY_PROFIL_DATA = "PATCH_MY_PROFIL_DATA"
export const PATCH_MY_PERSONAL_INFOS = "PATCH_MY_PERSONAL_INFOS"
export const LOADING_USER_REQUEST = " LOADING_USER_REQUEST"
export const PATCH_SUBSCRIPTIONS = " PATCH_SUBSCRIPTIONS"
export const PUT_MODIFY_PASSWORD = "PUT_MODIFY_PASSWORD"
export const LOADING_MODIFY_PASSWORD = "LOADING_MODIFY_PASSWORD"
export const DEFAULT_ADDRESS = "DEFAULT_ADDRESS"
export const ADD_WISHLIST = "ADD_WISHLIST"
export const WISHLIST_ITEM = "WISHLIST_ITEM"
export const PUT_WISHLIST_ITEM = "PUT_WISHLIST_ITEM"
export const DELETE_WISHLIST_ITEM = "DELETE_WISHLIST_ITEM"
export const FETCH_ADDRESSES = "FETCH_ADDRESSES"
export const FETCH_GIFTCARDS = "FETCH_GIFTCARDS"
export const FETCH_PDF_GIFTCARD = "FETCH_PDF_GIFTCARD"
export const ADD_GIFT_CARD_ACCOUNT = "ADD_GIFT_CARD_ACCOUNT"
export const POST_ACTIVATE_COURSE = "POST_ACTIVATE_COURSE"
export const FETCH_WISHLIST = "FETCH_WISHLIST"
export const FETCH_INVOICE = "FETCH_INVOICE"
export const FETCH_CUSTOMER_REVIEWS = "FETCH_CUSTOMER_REVIEWS"


export const fetchCustomerReviews = (id) => ({
    type : FETCH_CUSTOMER_REVIEWS,
    id : id
})

export const fetchInvoice = (token) => ({
    type: FETCH_INVOICE,
    token: token,
})

export const fetchWishlist = (token) => ({
    type: FETCH_WISHLIST,
    token: token,
})

export const postActivateCourse = (token) => ({
    type: POST_ACTIVATE_COURSE,
    token: token,
})

export const addGiftCardAccount = (code) => ({
    type: ADD_GIFT_CARD_ACCOUNT,
    code: code,
})

export const fetchPdfGiftcard = (code) => ({
    type: FETCH_PDF_GIFTCARD,
    code: code,
})

export const fetchGiftcards = (orderGiftcards) => ({
    type: FETCH_GIFTCARDS,
    orderGiftcards: orderGiftcards,
})

export const fetchAddresses = (context) => ({
    type: FETCH_ADDRESSES,
    context : context,
})

export const putWishlistItem = (userId, productId, ajoute) => ({
    type: PUT_WISHLIST_ITEM,
    userId: userId,
    productId: productId,
    ajoute: ajoute,
})

export const deleteWishlistItem = (token, productId) => ({
    type: DELETE_WISHLIST_ITEM,
    token: token,
    productId: productId,
})

export const wishlistItem = (productId) => ({
    type: WISHLIST_ITEM,
    productId: productId,
})

export const addWishlist = (newValue) => ({
    type: ADD_WISHLIST,
    value: newValue,
})

export const defaultAddress = (newValue, customer) => ({
    type: DEFAULT_ADDRESS,
    value: newValue,
    customer: customer,
})

export const loadingModifyPassword = (newValue) => ({
    type: LOADING_MODIFY_PASSWORD,
    value: newValue,
})

export const putModifyPassword = (newValue) => ({
    type: PUT_MODIFY_PASSWORD,
    value: newValue,
})

export const patchSubscriptions = (newValue, attribut) => ({
    type: PATCH_SUBSCRIPTIONS,
    value: newValue,
    attribut: attribut,
})

export const loadingUserRequest = (newValue) => ({
    type: LOADING_USER_REQUEST,
    value: newValue,
})

export const patchMyPersonalInfos = (newValue) => ({
    type: PATCH_MY_PERSONAL_INFOS,
    value: newValue,
})

export const patchMyProfilData = (id) => ({
    type: PATCH_MY_PROFIL_DATA,
    value: id,
})

export const loadingDeleteAddress = (newValue) => ({
    type: LOADING_DELETE_ADDRESS,
    value: newValue,
})

export const deleteAddress = (id) => ({
    type: DELETE_ADDRESS,
    value: id,
})

export const togglePopupError = (newValue, message) => ({
    type: TOGGLE_POPUP_ERROR,
    value: newValue,
    message: message,
})

export const loadingAddAddress = (newValue) => ({
    type: LOADING_ADD_ADDRESS,
    value: newValue,
})

export const postNewAddress = () => ({
    type: POST_NEW_ADDRESS,
})

export const errorModifyAddress = (newValue) => ({
    type: ERROR_MODIFY_ADDRESS,
    value: newValue,
})

export const saveCountries = (newValue) => ({
    type: SAVE_COUNTRIES,
    value: newValue,
})

export const fetchCountries = () => ({
    type: FETCH_COUNTRIES,
})

export const loadingModifyAddress = (newValue) => ({
    type: LOADING_MODIFY_ADDRESS,
    value: newValue,
})

export const putModifyAddress = (newValue) => ({
    type: PUT_MODIFY_ADDRESS,
    value: newValue,
})

export const saveWishlist = (newValue) => ({
    type: SAVE_WISHLIST,
    value: newValue,
})

export const defaultAddressActif = (newValue) => ({
    type: DEFAULT_ADDRESS_ACTIF,
    value: newValue,
})

export const isErrorPatternEmail = (newValue) => ({
    type: IS_ERROR_PATTERN_EMAIL,
    value: newValue,
})

export const toggleModifyAddress = (newValue) => ({
    type: TOGGLE_POPUP_MODIFY_ADDRESS,
    value: newValue,
})

export const loadingOrderItems = (newValue) => ({
    type: LOADING_ORDER_ITEMS,
    value: newValue,
})

export const saveOrderItems = (newValue) => ({
    type: SAVE_ORDER_ITEMS,
    value: newValue,
})

export const fetchOrderItems = (newValue) => ({
    type: FETCH_ORDER_ITEMS,
    value: newValue,
})

export const toggleItemsOrder = (newValue) => ({
    type: TOGGLE_ITEMS_ORDER,
    value: newValue,
})

export const saveOrders = (newValue) => ({
    type: SAVE_ORDERS,
    value: newValue,
})

export const fetchOrders = (newValue) => ({
    type: FETCH_ORDERS,
    value: newValue,
})

export const checkSave = (newValue) => ({
    type: CHECK_SAVE,
    value: newValue,
})

export const fetchUserData = (newValue, userToken) => ({
    type: FETCH_USER_DATA,
    value: newValue,
    token: userToken,
})

export const changeImageUrl = (newValue) => ({
    type: CHANGE_IMAGE_URL,
    value: newValue,
})

export const changeImageFile = (newValue) => ({
    type: CHANGE_IMAGE_FILE,
    value: newValue,
})

export const visibilityPassword = (newValue, stateValue) => ({
    type: VISIBILITY_PASSWORD,
    value: newValue,
    stateName: stateValue,
})

export const isErrorConfirmPassword = (newValue) => ({
    type: IS_ERROR_CONFIRM_PASSWORD,
    value: newValue,
})

export const isErrorPatternPassword = (newValue) => ({
    type: IS_ERROR_PATTERN_PASSWORD,
    value: newValue,
})

export const togglePopUpPassword = (newValue) => ({
    type: TOGGLE_POP_UP_PASSWORD,
    value: newValue,
})

export const changeInputValue = (newValue, inputName) => ({
    type: CHANGE_INPUT_VALUE,
    value: newValue,
    name: inputName,
})
