import { useDispatch, useSelector } from 'react-redux';
import { askReview, getJsonCookie, setJsonCookie } from '../../../../selectors/selectors';
import './Overlay.scss';
import { useParams } from 'react-router-dom';
import { saveUserMedia } from '../../../../actions/mediaAction';

const Overlay = () => {

    const collection = useSelector((state) => state.media.collection);
    const overlayButtonContent = useSelector((state) => state.media.overlayButtonContent);
    const dispatch = useDispatch();

     return (
        <div className="overlay-wrapper">
            <div className='overlay-element-wrapper'>
                <div className='image-wrapper'>
                    <img src="https://static.artesane.com/images_react/favicon.png" alt="artesane" />
                </div>
                <div className='text big'>Votre avis est précieux &#x2665;</div>
                <div className='text'>Partagez votre expérience et aidez-nous à améliorer encore nos cours</div>
                 <div className='text'>Prenez une minute pour nous dire ce que vous pensez du produit <span className='bold'>{collection.collection.name}</span> </div>
                 <div className='overlay-button'>
                    <button
                        className='normal'
                        onClick={() => {
                            dispatch(saveUserMedia('reviewFormOpen', true))
                        }}
                    >
                        Je donne mon avis {">"}
                    </button>
                    <button
                        className='close-button'
                        onClick={() => {

                            dispatch(saveUserMedia("overlayButtonContent", "Une autre fois peut-être"))
                            dispatch(saveUserMedia("overlayOpen", false))
                            dispatch(saveUserMedia("isVideoPlaying", true))


                        }}
                    >
                        {overlayButtonContent} X 
                    </button>
                 </div>
            </div>
        </div>
     )
}


export default Overlay;