import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ProductSchema = ({ reviewsItemsNumber, averageRating }) => {


  const location = useLocation();
  const productData = useSelector((state) => state.products.productData);

  const schemaData = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": productData.name,
    "productID": productData.id,
    "description": productData.metaDescription,
    "image": productData.mainImage ? [productData.mainImage.url] : [],
    "brand": {
      "@type": "Brand",
      "name": "Artesane"
    },
    "sku": productData.code,
    "offers": {
      "@type": "Offer",
      "url": "https://www.artesane.com" + location.pathname,
      "priceCurrency": "EUR",
      "price": productData.variants[0].price === 0 
               ? "0" 
               : (productData.variants[0].price / 100).toFixed(2),
      "availability": productData.variants[0].inStock 
                      ? "https://schema.org/InStock" 
                      : "https://schema.org/OutOfStock",
      "itemCondition": "https://schema.org/NewCondition"
    }
  };
  
  if (reviewsItemsNumber > 0) {
    schemaData.aggregateRating = {
      "@type": "AggregateRating",
      "ratingValue": averageRating.toFixed(1),
      "reviewCount": reviewsItemsNumber
    };
  }
  

  return (
    <script type="application/ld+json">
      {JSON.stringify(schemaData)}
    </script>
  );
};

export default ProductSchema;
