import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import './Reviews.scss';
import { useDispatch, useSelector } from 'react-redux';
import { saveProductDataState } from '../../../../actions/productsActions';
import GiveReviewButton from './GiveReviewButton/GiveReviewButton';

const Reviews = ({averageRating}) => {
    
    const dispatch = useDispatch();
    const reviewsNumber = useSelector((state) => state.products.reviewsItemsNumber);
    const userConnected = useSelector((state) => state.auth.userConnected);
    const associations = useSelector((state) => state.media.associations)
    const productData = useSelector((state) => state.products.productData)
    const isloadingMedia = useSelector((state) => state.media.isloadingMedia)




    return (
        <>
        
        {reviewsNumber > 0 && (
            <div 
            className='reviews-product-wrapper'
            onClick={() => {
                dispatch(saveProductDataState("reviewsSidebarOpen", true))
            }}
        >
                <Box sx={{ width: 200, display: 'flex', alignItems: 'center' }}>
                    <Rating name="half-rating-read" size="small" defaultValue={averageRating} precision={0.5} readOnly />
                    <Box sx={{marginLeft : '7px', color : 'grey'}}>{reviewsNumber} avis</Box>
                </Box>
            </div>
        )}
        { reviewsNumber === 0 && userConnected && !isloadingMedia && (
            associations.some((item) => 
                item.collection.products &&
                item.collection.products.length > 0 &&
                item.collection.products[0].product['@id'] === productData["@id"]
                )) && (
                <GiveReviewButton/>
        )}
    </>
    )
}

export default Reviews;