import { useDispatch, useSelector } from 'react-redux';
import Rating from '@mui/material/Rating';
import './ReviewsSidebar.scss';
import {fetchProductReviews, saveProductDataState } from '../../../../actions/productsActions';
import OutsideClickHandler from "react-outside-click-handler"
import Spinner from '../../../Commons/Spinner/Spinner';
import { useEffect, useState } from 'react';
import { loadingMedia, saveUserMedia } from '../../../../actions/mediaAction';
import GiveReviewButton from '../Rewiews/GiveReviewButton/GiveReviewButton';


const ReviewsSidebar = ({averageRating, productCode}) => {

    const dispatch = useDispatch()
    const reviewsSidebarOpen = useSelector((state) => state.products.reviewsSidebarOpen);
    const reviewsNumber = useSelector((state) => state.products.reviewsItemsNumber);
    const reviewsPage = useSelector((state) => state.products.reviewsPage);
    const reviews = useSelector((state) => state.products.reviews);
    const loadingReviews = useSelector((state) => state.products.loadingReviews);
    const userConnected = useSelector((state) => state.auth.userConnected);
    const associations = useSelector((state) => state.media.associations)
    const productData = useSelector((state) => state.products.productData)
    const isloadingMedia = useSelector((state) => state.media.isloadingMedia)
    const reviewFormOpen = useSelector((state) => state.media.reviewFormOpen)



    return (
        <div className={`reviews-sidebar-wrapper ${reviewsSidebarOpen ? "open" : "close"}`}>
            <OutsideClickHandler
                onOutsideClick={() => {

                    if(!reviewFormOpen) {
                        dispatch(saveProductDataState("reviewsSidebarOpen", false))

                    }
                }}>
                    <div className={`reviews-sidebar-reviews-wrapper ${reviewsSidebarOpen ? "open" : "close"}`}> 


                            <div className='reviews-sidebar-reviews-intern-wrapper'>
                                <div
                                className='cross'
                                onClick={() => {
                                    dispatch(saveProductDataState("reviewsSidebarOpen", false))
                                }}
                                >
                                    ╳
                                </div>
                                {loadingReviews && (
                                    <Spinner/>
                                )}

                                <div className='reviews-sidebar-reviews-intern-wrapper_title'>Avis</div>
                                <div className='reviews-rating-and-numbers'>
                                    <div className='total-rating'>
                                        <div>{averageRating.toFixed(1).toString().replace('.', ',')}</div>
                                        <Rating name="half-rating-read" defaultValue={averageRating} precision={0.5} readOnly />
                                    </div>
                                    <div>
                                        <div className='reviews-number'>
                                        Basé sur {reviewsNumber} avis clients
                                        </div>
                                        <GiveReviewButton/>
                                    </div>
                                </div>
                                {reviews.map(({title, rating, comment, createdAt, author }) =>{ 
                                    let date = new Date(createdAt);
                                    let month = new Date(createdAt).toLocaleString('fr-FR', {month : 'short'})

                                    return (
                                    <div className='reviews-item'>
                                        <div className='review-rate-and-date'>
                                            <Rating name="half-rating-read" size="small" defaultValue={rating} precision={0.5} readOnly />
                                            {date.getDate() + " " + month + " "+ date.getFullYear()}
                                        </div>
                                        <div className='review-username' > par {author.username}</div>
                                        <div className='review-title'>
                                            {title}
                                        </div>
                                        <div className='review-content'>
                                            {comment}
                                        </div>

                                    </div>
                                )})}

                                {reviews.length < reviewsNumber && (
                                    <div
                                        className='button'
                                        onClick={() => {
                                            dispatch(saveProductDataState('loadingReviews', true))
                                            dispatch(saveProductDataState("reviewsPage", reviewsPage + 1))

                                            dispatch(fetchProductReviews(productCode, reviewsPage + 1, 'pages'))
                                        }}
                                    >
                                        Afficher plus
                                    </div>
                                )}

                            </div>
                    </div>
                </OutsideClickHandler>
        </div>
    );
}


export default ReviewsSidebar