import { NavLink, useParams } from "react-router-dom"
import Header from "../../Prepage/Header/Header"
import "./MediaDashboard.scss"
import "./MediaDashboard.scss"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    fetchSingleCollection,
    loadingCollection,
} from "../../../actions/mediaAction"
import Spinner from "../../Commons/Spinner/Spinner"
import Banner from "../../Banner/Banner"
import { setCookie } from "../../../selectors/selectors"
import GiveReviewButton from "../../Product/TopInformations/Rewiews/GiveReviewButton/GiveReviewButton"
import ReviewForm from "../Player/ReviewForm/ReviewForm"

const MediaDashboard = () => {
    const { collectionId } = useParams()
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.media.loadingCollection)
    const collectionData = useSelector((state) => state.media.collection)
    const reviewFormOpen = useSelector((state) => state.media.reviewFormOpen)

    useEffect(() => {
            dispatch(loadingCollection(true))
            dispatch(fetchSingleCollection(collectionId))
    
    }, [collectionId, dispatch])

    const allCoursesDocuments = [];

    return (
        <div>
            <Banner />
            <Header />
            {reviewFormOpen &&  !isLoading && (
                <ReviewForm productName={collectionData.collection.products[0].product.name} productUri={collectionData.collection.products[0].product['@id']}/>
            )}
   
            <div className="mediadashboard-wrapper">
                {isLoading && (
                    <div className="spinner-wrapper">
                        <Spinner />
                    </div>
                )}
                {!isLoading && (
                    <>
                 
                        {collectionData.collection.documents.length === 0 && (
                            <div className="condition-title"> Cours</div>
                        )}
                        {collectionData.collection.documents.length > 0 &&
                            collectionData.collection.courses.length < 1 && (
                                <div className="condition-title">
                                    {" "}
                                    documents
                                </div>
                            )}
                        <div className="return">
                            <NavLink
                                to={`/mes-cours-et-documents/${collectionData.collection.universes.length > 0 ? collectionData.collection.universes[0].code : "arts-du-fil"}`}>
                                {" "}
                                {"< "}Retour à ma médiathèque
                            </NavLink>
                        </div>
                        <div className="ask-review-wrapper">
                            <GiveReviewButton/>
                        </div>
                        {collectionData.collection.courses.map(
                            ({
                                id,
                                duree,
                                author,
                                name,
                                artesane_sellable_item_description,
                                documents,
                                images,
                                savedTimes,
                                code,
                            }) => {
                                console.log(id)

                                allCoursesDocuments.push(...documents)
                                return (
                                    <div
                                        key={name}
                                        className="videos-and-pdf-wrapper">
                                        <div className="media-dashboard-title">
                                            <span className="cours-title">
                                                {name}
                                            </span>
                                            <span className="cours-teacher">
                                                {author !== null
                                                    ? author.name
                                                    : ""}
                                            </span>
                                        </div>

                                        <div className="img-and-pdf-wrapper">
                                            <div className="img-card-wrapper">
                                                <NavLink
                                                    to={`/player/${collectionData.id}/${code}/${id}`}>
                                                    <img
                                                        className="main-image-cours"
                                                        src={
                                                            images.length > 0
                                                                ? images[0]
                                                                      .thumbnails
                                                                      .carousel
                                                                : ""
                                                        }
                                                        alt="le cours"
                                                    />
                                                    <img
                                                        className="player-logo"
                                                        src={
                                                            "https://static.artesane.com/images_react/play-noir.png"
                                                        }
                                                        alt="lecture"
                                                    />
                                                    <div className="white-filter"></div>
                                                    <div className="progression-bar">
                                                        <div
                                                            style={{
                                                                width: `${(100 * (Object.keys(savedTimes).length > 0 ? savedTimes.timeInVideo : 0)) / (duree !== null ? duree : 0)}%`,
                                                            }}
                                                            className="progression-bar-color"></div>
                                                    </div>
                                                </NavLink>
                                            </div>

                                            <div className="document-pdf-wrapper">
                                                
                                                <div className="short-description-wrapper">
                                                    {
                                                        artesane_sellable_item_description
                                                    }
                                                </div>

                                                {documents.length > 0 &&
                                                    documents.map(
                                                        ({
                                                            name,
                                                            file,
                                                            id,
                                                        }) => {
                                                            return (
                                                                <div
                                                                    key={id}
                                                                    className="pdf-item">
                                                                    <a
                                                                        onClick={() => {
                                                                            setCookie(
                                                                                "CloudFront-Key-Pair-Id",
                                                                                file
                                                                                    .access[
                                                                                    "Cloudfront-Key-Pair-Id"
                                                                                ],
                                                                                7
                                                                            )
                                                                            setCookie(
                                                                                "CloudFront-Policy",
                                                                                file
                                                                                    .access[
                                                                                    "Cloudfront-Policy"
                                                                                ],
                                                                                7
                                                                            )
                                                                            setCookie(
                                                                                "CloudFront-Signature",
                                                                                file
                                                                                    .access[
                                                                                    "Cloudfront-Signature"
                                                                                ],
                                                                                7
                                                                            )
                                                                        }}
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                        href={
                                                                            file
                                                                                .access
                                                                                .URL
                                                                        }>
                                                                        <img
                                                                            className="pdf-logo"
                                                                            src={
                                                                                "https://static.artesane.com/images_react/pdf-logo.png"
                                                                            }
                                                                            alt="pdf"
                                                                        />
                                                                        <span className="pdf-title">
                                                                            {
                                                                                name
                                                                            }
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                        {/* {documents.length < 1 && (
                                 <div className="media-dashboard-title only">
                                    <span className="only-cours-title">{name}</span>
                                    <span className="cours-teacher">{author.name}</span>
                                </div>
                            )} */}
                                    </div>
                                )
                            }
                        )}
                        {collectionData.collection.documents.length > 0 && (
                            <div className="document-pdf-wrapper">

                                { collectionData.collection.documents.filter(item => !allCoursesDocuments.some(existingItem => existingItem.id === item.id)).map(
                                    ({ name, file }) => (
                                        <div key={name} className="pdf-item ">
                                            <a
                                                onClick={() => {
                                                    setCookie(
                                                        "CloudFront-Key-Pair-Id",
                                                        file.access[
                                                            "Cloudfront-Key-Pair-Id"
                                                        ],
                                                        7
                                                    )
                                                    setCookie(
                                                        "CloudFront-Policy",
                                                        file.access[
                                                            "Cloudfront-Policy"
                                                        ],
                                                        7
                                                    )
                                                    setCookie(
                                                        "CloudFront-Signature",
                                                        file.access[
                                                            "Cloudfront-Signature"
                                                        ],
                                                        7
                                                    )
                                                }}
                                                rel="noreferrer"
                                                className="center"
                                                target="_blank"
                                                href={file.access.URL}>
                                                <img
                                                    className="pdf-logo"
                                                    src={
                                                        "https://static.artesane.com/images_react/pdf-logo.png"
                                                    }
                                                    alt="pdf"
                                                />
                                                <span className="pdf-title">
                                                    {name}
                                                </span>
                                            </a>
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default MediaDashboard
