import { useDispatch, useSelector } from 'react-redux';
import Header from '../Prepage/Header/Header';
import './GiveReview.scss';
import { postReview, saveUserMedia } from '../../actions/mediaAction';
import { useParams } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import InputSimple from '../UserAccount/InputSimple/inputSimple';
import Banner from '../Banner/Banner';
import LinkButton from '../Commons/Buttons/LinkButton/LinkButton';
import Spinner from '../Commons/Spinner/Spinner';
import { useEffect } from 'react';
import { fetchProductData } from '../../actions/productsActions';
import NotFound from '../Commons/NotFound/NotFound';


const GiveReview = () => {
        const dispatch = useDispatch()
        const reviewFormRate = useSelector((state) => state.media.reviewFormRate)
        const {productSlug} = useParams()
        const reviewFormContent = useSelector((state) => state.media.reviewFormContent)
        const reviewTitle = useSelector((state) => state.media.reviewTitle)
        const errorRate = useSelector((state) => state.media.errorRate)
        const errorContent = useSelector((state) => state.media.errorContent)
        const customerEmail = useSelector((state) => state.user.email);
        const reviewConfirm = useSelector((state) => state.media.reviewConfirm)
        const loadingReviewFormSubmit = useSelector((state) => state.media.loadingReviewFormSubmit)
        const isLoadingProduct = useSelector((state) => state.products.isLoadingProduct)
        const productData  = useSelector((state) => state.products.productData)
        const notFoundProduct = useSelector(
            (state) => state.products.notFoundProduct
        )

        useEffect(() => {
            dispatch(fetchProductData(productSlug))
        }, [dispatch])

    return (
        <div>
            <Banner />
            <Header/>

            <div className='give-review-wrapper'>

        {!isLoadingProduct && !notFoundProduct && (
            <>

        
            <div className='review-form-title'>
                   <div>Une minute pour vous, une aide précieuse pour nous !</div>
                    <div> Merci pour votre retour sur le produit "{productData.name}"</div>
            </div>
            
            {!reviewConfirm && (
                            <form
                            className='review-form-container'
                            onSubmit={(event) => {
                                event.preventDefault();
        
                                if(reviewFormRate < 1 ) {
                                    dispatch(
                                        saveUserMedia('errorRate', true)
                                    )
                                }
        
                                if(reviewFormContent.trim().length < 3) {
                                    dispatch(
                                        saveUserMedia('errorContent', true)
                                    )
                                }
        
                                if(reviewFormRate > 0 && reviewFormContent.trim().length > 2 ) {
                                    dispatch(
                                        saveUserMedia('loadingReviewFormSubmit', true)
                                    )
                                    dispatch(postReview(reviewFormRate, reviewFormContent, reviewTitle,  productData['@id'], customerEmail))
                                }
                            }}
                        >
                            <div className={`${errorRate ?  'input-value error' : 'input-value'}`}>
                                <label  htmlFor="">Votre note : </label>
                                <Rating
                                    name="simple-controlled"
                                    value={reviewFormRate}
                                    size="large"
                                    onChange={(event, newValue) => {
                                        dispatch(saveUserMedia('reviewFormRate', newValue));
                                        dispatch(
                                            saveUserMedia('errorRate', false)
                                        )
                                    }}
                                />
                                {errorRate && (
                                    <div className='input-information error'>
                                        Une étoile minimum est requise
                                    </div>
                                )}
                            </div>
                            <InputSimple
                                inputName={"reviewTitle"}
                                label={"Titre"}
                                value={reviewTitle}
                                type={"text"}
                                required={false}
                                onChange={(event) => {
                                    dispatch(saveUserMedia('reviewTitle', event.target.value));
                                    
                                }}
                            />
                            <div className={`${errorContent ?  'input-value error' : 'input-value'}`}>
                                <label htmlFor="description">Votre avis</label>
                                <textarea
                                    minLength={3}
                                    value={reviewFormContent}
                                    name="description"
                                    cols="30"
                                    rows="8"
                                    onChange={(event) => {
                                        dispatch(
                                            saveUserMedia('reviewFormContent', event.target.value)
                                        )
                                        dispatch(
                                            saveUserMedia('errorContent', false)
                                        )
                                    }}></textarea>
                                {errorContent && (
                                    <div className='input-information error'>
                                        Votre commentaire doit contenir au minimum 3 caractères
                                    </div>
                                )}
                            </div>
                            <button type='submit'>
                                valider
                            </button>
                        </form>
            )}
            {reviewConfirm && (
                <div className='review-confirm'>
                    <div className='text'>
                        Votre avis a bien été envoyé !
                    </div>

                    <LinkButton url={'/'} texte={'Retour sur Artesane'}/>
                    <img src={`https://static.artesane.com/images_react/favicon.png`} alt="artesane" />
                </div>
            )}

            
            {loadingReviewFormSubmit && (
                <Spinner/>
            )}
            </>
        )}

        {notFoundProduct && (
            <NotFound/>
        )}

            </div>

        </div>
    )
}


export default GiveReview;