import { useDispatch, useSelector } from 'react-redux';
import './GiveReviewButton.scss';
import { saveUserMedia } from '../../../../../actions/mediaAction';

const GiveReviewButton = () => {


    const dispatch= useDispatch()
    const reviewsNumber = useSelector((state) => state.products.reviewsItemsNumber);
    const userConnected = useSelector((state) => state.auth.userConnected);
    const associations = useSelector((state) => state.media.associations)
    const productData = useSelector((state) => state.products.productData)
    const isloadingMedia = useSelector((state) => state.media.isloadingMedia)

    return (
      
            <div 
            className='asking-review'
            onClick={() => {
                dispatch(saveUserMedia('reviewFormOpen', true))
            }}
            >
            Je laisse un avis {">"}
            </div>
    
    )
}

export default GiveReviewButton;